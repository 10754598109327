export default [
  {
    title: 'Главный слайдер',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Наши услуги',
    route: 'services',
   
  },
  {
    title: 'Отзывы',
    route: 'reviews',
   
  },
  {
    title: 'Наша история',
    route: 'history',
  
  },
  {
    title: 'Преимущества и статистика',
    route: 'statistic',
    
  },
  {
    title: 'Наша команда',
    route: 'team',
    
  },
  // {
  //   title: 'Статистика',
  //   route: 'staticcount',
    
  // },
  {
    title: 'Новости',
    route: 'news',
    
  },
  {
    title: 'Галерея',
    route: 'gallery',
    
  },
  {
    title: 'Вопросы',
    route: 'faq-category',
    
    children:[
  
      {
        title: 'Категория вопросов',
        route: 'faq-category',
        
      },
      {
        title: 'Часто задаваемые вопросы',
        route: 'faqy',
        
      },

    ]
  },
  {
    title: 'Процедуры',
    route: 'procedures',
    
    children:[
  {
        title: 'Виды процедур',
        route: 'proceduresandroom',
      
      },
      {
        title: 'Категории',
        route: 'procedures-category',
        
      },

    ]
  },
  {
    title: 'Номера',
    route: 'roomandstat',
    icon: 'HomeIcon',
    children:[
  
      {
        title: 'Категория номеров',
        route: 'room-category',
        icon: 'HomeIcon',
      },
      {
        title: 'Все номера',
        route: 'rooms',
        icon: 'HomeIcon',
      },

    ]
  },
]
